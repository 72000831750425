<template>
    <main
        class="flex flex-col items-center justify-center min-h-screen gap-6 px-6 pt-10 pb-8 antialiased bg-cream-light md:h-screen md:py-20"
    >
        <router-view />
    </main>
</template>

<script setup>
import { onMounted, onBeforeUnmount } from 'vue'
import { useConnectionStatusStore } from '@/store/connection-status'
const { updateIsOffline, updateLastSync } = useConnectionStatusStore()

const syncData = () => {
    updateLastSync(new Date())
}

const handleConnectionChange = (event) => {
    if (event.type == 'offline') {
        console.log('Offline 🔴')
        updateIsOffline(true)
    }
    if (event.type == 'online') {
        console.log('Online 🟢')
        updateIsOffline(false)
        syncData()
    }
}

onMounted(() => {
    window.addEventListener('offline', handleConnectionChange)
    window.addEventListener('online', handleConnectionChange)
})

onBeforeUnmount(() => {
    window.removeEventListener('offline', handleConnectionChange)
    window.removeEventListener('online', handleConnectionChange)
})
</script>
